<template>
	<div class="ui-text-content text-content">
		<glossary v-if="glossary" :text="formated_html" :glossary="glossary" />
		<span v-else v-html="formated_html"></span>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getStackMedia, getLocalMedia, recomposeStackHtml } from '@/js/utils/recompose-stack-html'
import Hammer from 'hammerjs'
import Feature from 'feature.js'
import Glossary from '@/components/glossary/glossary'
import Apollo from "@/apollo/apollo-client"

export default {
	name: 'ui-text-content',
	props: [ 'html' ],
	data() {
		return {
			mc: false,
			formated_html: ''
		}
	},
	computed: {
		...mapGetters([ 'glossary' ])
	},
	watch: {
		html() {
			this.format_html()
		}
	},
	methods: {
		fetchHtml() {
			return new Promise((resolve) => {
				const { html } = this
				const getMediaFunction = getStackMedia
				let parser = new DOMParser()
				let result = recomposeStackHtml({ html, getMediaFunction: getLocalMedia(this.$medias) })
					.then(async x => {
						let htmlDoc = parser.parseFromString(x || '', 'text/html')
						let links = htmlDoc.querySelectorAll('a')
						for (let i = 0; i < links.length; i++) {
							let is_pdf = links[i].href.toLowerCase().split('.').pop() == 'pdf'
							if (is_pdf && !Feature.touch) {
								links[i].setAttribute('target', 'blank')
								links[i].setAttribute('data-src', links[i].href)
								links[i].removeAttribute('href')
							}
							const pathname = links[i].pathname
							const isMedia = !pathname.includes('.')
							if (isMedia) {
								const slug = pathname.substring(1, pathname.length)
								const result = await Apollo.query('CLIENT_MEDIA_BY', { slug })
								if (Array.isArray(result) && result.length) {
									const { url } = result[0]
									if (url) {
										links[i].setAttribute('data-src', url)
										links[i].removeAttribute('href')
									}
								}
							}
						}

						let imgs = htmlDoc.querySelectorAll('img')
						for (let i = 0; i < imgs.length; i++) {
							$(imgs[i]).wrap('<a data-src="' + imgs[i].src + '"></a>')
						}

						let html = htmlDoc.documentElement.innerHTML.replace('<head></head><body>', '').replace('</body>', '')
						resolve(html)
					})
				return result
			})
		},
		async format_html() {
			this.formated_html = (await this.fetchHtml()).replaceAll('<a href', '<a target="_blank" href')
			if (this.mc) this.mc.destroy()
			this.mc = new Hammer.Manager(this.$el)
			this.mc.add( new Hammer.Tap() )
			this.mc.on("tap", async (e) => {
				let $current = $(e.target)
				if (!e.target.getAttribute('data-src')) $current = $current.parents('a[data-src]')
				let $items = $(this.$el).find('[data-src]')
				let index = $items.index($current)
				if (index >= 0) {
					$.fancybox.open($items, {
						buttons: ['close'],
						protect: true,
						infobar: false,
						toolbar: true,
					}, index)
				}
			})
		}
	},
	async mounted() {
	  await this.format_html()
	},
	components: { Glossary }
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/variables'
@import '../../assets/css/text-content'

#app.mobile
	.ui-text-content
		line-height 18px

.ui-text-content
	display block
	line-height 22px
	word-break break-word
	>>> p
		margin 0 0 4px 0
		&:last-child
			margin-bottom 0
	>>> ul
		margin-left 24px
		list-style-type disc
	>>> img
		max-width 100%
	>>> li.ql-indent-1
		margin-left 30px

</style>
