<template>
	<div class="dialog-box">
		<div class="bg" v-hammer:tap="()=> close()"></div>
		<div class="dialog-box-content">
			<div class="modal" :class="{ 'no-title': (!modal.title && !modal.character) }">
				<div v-if="modal.character" class="patient-img">
					<div class="img" ref="patient_image"></div>
					<!-- <img src="@/assets/img/patient.svg"> -->
				</div>
				<div v-if="modal.title || modal.character" class="modal-title">
					<span v-if="modal.title">{{ modal.title }}</span>
					<span v-else>{{ modal.character.name }}</span>
				</div>
				<div class="modal-text scrollable-bottom" v-scrollbar>
					<div>
						<div style="max-height: 350px">
							<ui-text-writer v-if="modal.animation && modal.animation == 'typewriter'" :text="modal.text" />
							<ui-text-content v-else :html="modal.text" />
						</div>
					</div>
				</div>
				<div v-if="modal.has_close" class="modal-close" v-hammer:tap="()=> close()"></div>

				<ui-button v-if="modal.button" class="modal-button" color="red" :loading="app.loading" v-hammer:tap="()=> complete_step()">{{ modal.button.text }}</ui-button>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import UiTextWriter from '@/components/ui/ui-text-writer'
import UiTextContent from '@/components/ui/ui-text-content'
import {manageLottie} from "@/js/managers/manageLottie"
import {garbageCollector} from "@/js/utils/garbageCollector"

export default {
	name: 'dialog-box',
	props: [ 'modal' ],
	computed: {
		...mapGetters([ 'app', 'game' ]),
		character_url() {
			return this.$medias.get({ id: this.game.ui.character_animation.id })
		}
	},
	data() {
		return {
			animations: [],
		}
	},
	watch: {
		character_url(newValue) {
			if (newValue) this.$nextTick(() => { this.load_character() })
		}
	},
	methods: {
		close() {
			if (this.game.current.step.layout != 'feedback' && this.game.current.step.layout != 'feedback_no_image' && this.game.current.step.layout != 'intro') {
				this.$store.dispatch('set_active_modal', { modal: false })
			}
		},
		complete_step() {
			if (this.game.current.step.layout == 'feedback' || this.game.current.step.layout == 'feedback_no_image' || this.game.current.step.layout == 'intro') {
				this.$store.dispatch('complete_current_step')
			} else
				this.$store.dispatch('set_active_modal', { modal: false })
		},
		load_character() {
			if (this.modal?.character?.name && this.character_url) {
				this.animations = [
					manageLottie.create({
						container: this.$refs.patient_image,
						path: this.character_url,
						renderer: 'svg',
						loop: true,
						autoplay: this.app.performance !== 'low',
						rendererSettings: {
							preserveAspectRatio: 'xMidYMax slice',
						}
					})
				]
			}
		}
	},
	updated() {
		if (!this.animations.length) this.load_character()
	},
	mounted() {
		this.load_character()
	},
	components: { UiTextWriter, UiTextContent },
	beforeDestroy() {
		this.animations.length = 0
		setTimeout(() => {
			garbageCollector.destroy()
		}, 1000)
	}
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/variables'


#app.perf-normal, #app.perf-high
	.dialog-box-enter-active,
	.dialog-box-leave-active,
	.dialog-box-enter-active .dialog-box-content,
	.dialog-box-leave-active .dialog-box-content
		transition 0.25s easeOutQuart

	.dialog-box-enter, .dialog-box-leave-to
		opacity 0

	.dialog-box-enter .dialog-box-content
		transform translateY(-50px)
		opacity 0
	.dialog-box-leave-to .dialog-box-content
		transform translateY(-50px)
		opacity 0


#app.desktop, #app.tablet
	.dialog-box
		.dialog-box-content
			.modal
				width 400px

#app.mobile
	.dialog-box
		z-index 2

.dialog-box
	position absolute
	left 0
	top 0
	right 0
	bottom 0
	display flex
	align-items center
	justify-content center
	// background-color alpha(dark, 80%)
	.bg
		position absolute
		left 0
		top 0
		right 0
		bottom 0
		background-color alpha(dark, 80%)
		// transition 0.35s easeOutQuart
	.dialog-box-content
		// position absolute
		// left 0
		// top 0
		// right 0
		// height calc(100% - 64px)
		// height 100%
		.modal
			// position absolute
			// left 50%
			// top 50%
			// transform translate(-50%, -50%)
			width 300px
			padding 28px 16px 16px 16px
			background-color #fff
			&.no-title
				padding-top 16px
			.patient-img
				position absolute
				left 50%
				top -150px
				height 150px
				width 100%
				transform translateX(-50%)
				overflow hidden
				// background-color blue
				img
					position absolute
					left 50%
					top 65%
					height 400%
					transform translate(-50%, -50%)
				.img
					position absolute
					left 25%
					top 0
					width 50%
			.modal-title
				position absolute
				left 0
				top -16px
				height 32px
				padding 0 32px
				line-height @height
				background-color dark
				border-radius 12px
				border-bottom-left-radius 0
				color #fff
				border 1px solid #fff
			.modal-text
				text-align left
				color dark
				// height 500px
				.modal-text-content
					max-height 450px
					overflow auto
			.modal-close
				position absolute
				right 2px
				top -16px
				height 32px
				width 32px
				background dark url(../../assets/img/close.svg) center center no-repeat
				background-size 50% 50%
				border-radius 50%
				box-shadow 0 0 0 2px #fff
				cursor pointer
				line-height @height
				text-align center
				color #fff
				font-size 2rem
			.modal-button
				position absolute
				left 50%
				top calc(100% + 32px)
				transform translateX(-50%)





</style>
