<template>
	<span class="glossary">
		<transition name="definition">
			<div v-if="activeDefinition" class="definition" @mouseenter="close()" v-hammer:tap="() => close()">
				<div class="definition-word">{{ activeWord }}</div>
				<div class="definition-text">{{ activeDefinition }}</div>
			</div>
		</transition>
		<span
			v-for="word in words"
		>
			<span v-if="isSpaces(word)">
				<pre v-for="i in word.length" class="space"></pre>
			</span>
			<span
				v-else-if="glossaryWords.includes(word)"
				v-hammer:tap="() => click(word)"
				@mouseenter="open(word)"
				@mouseleave="close()"
				class="word"
				v-html="word"
			></span>
			<span v-else v-html="word"></span>
		</span>
	</span>
</template>

<script>
import { glossarise } from '@/js/utils/utils'

export default {
	name: 'glossary',
	props: [ 'text', 'glossary' ],
	data() {
		return {
			activeWord: null
		}
	},
	computed: {
		activeDefinition() {
			return this.glossary[this.activeWord]
		},
		glossaryWords() {
			return Object.keys(this.glossary)
		},
		words() {
			return glossarise(this.text)
		},
	},
	methods: {
		isSpaces(word) {
			return word.trim() === ''
		},
		click(word) {
			this.activeWord = word
		},
		open(word) {
			this.activeWord = word
		},
		close() {
			this.activeWord = null
		}
	}
}
</script>

<style scoped lang="stylus">
@import '../../assets/css/variables'

.glossary
	user-select text
	.definition
		position absolute
		left 50%
		width 250px
		padding 12px 12px 12px 40px
		// border 2px solid #ccc
		border-radius 8px
		bottom calc(100% + 8px)
		background-color #eee
		font-size 1.4rem
		line-height 1.4em
		transform translateX(-50%)
		transition 0.15s easeOutQuart
		text-align left
		box-shadow 0 0 4px alpha(#000, 50%)
		z-index 20
		&:after
			content ''
			position absolute
			// left calc(50% - 20px)
			left -10px
			top -10px
			width 40px
			height 40px
			background #eee url('../../assets/img/help-circle.svg') center center no-repeat
			background-size 95%
			border-radius 50%

		&.definition-enter, &.definition-leave-to
			transform translate(-50%, 8px)
			opacity 0
		.definition-word
			font-weight 700
			text-transform uppercase
		.definition-text
			font-weight 400
			color #666
	.space
		display inline-block
		font-size .6em
		&:before
			content ' '
	.word
		cursor help
		&:after
			content ''
			position absolute
			left 0
			right 0
			bottom 0
			border-bottom 1px dotted dark
		&:hover
			// border-bottom 1px solid dark
			background-color #FFECB3
	
</style>
