<template>
	<div class="ui-text-writer" v-html="text" v-hammer:tap="()=> see_all()"></div>
</template>

<script>
import Typewriter from 'typewriter-effect/dist/core'

export default {
	name: 'ui-text-writer',
	props: [ 'text' ],
	data() {
		return {
			typewriter: null,
		}
	},
	methods: {
		see_all() {
			this.$el.innerHTML = this.text
		}
	},
	mounted() {
		this.$el.style.height = this.$el.clientHeight + 'px'
		this.$el.innerHTML = ''
		this.typewriter = new Typewriter(this.$el, {
			strings: this.text,
			autoStart: true,
			cursor: '',
			delay: 2,
			loop: false
		})
	},
	beforeDestroy() {
		this.typewriter = null
	}
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/variables'

#app.mobile
	.ui-text-writer
		line-height 18px

.ui-text-writer
	line-height 22px
	>>> .Typewriter__cursor
		display none
	>>> p
		margin 0 0 4px 0
		&:last-child
			margin-bottom 0



</style>
